html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif; 
}
.App {
  text-align: center;
  height: 100%;
  width: 100%;
}