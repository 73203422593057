// src/styles/_articles.scss

.creative-portfolio {
    &__articles {
      padding: 100px 0;
      display: flex;
      justify-content: center;
  
      .container {
        width: 60vw;
        
        @media (max-width: 768px) {
          width: 90vw;
        }
      }
  
      .articles-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 2rem;
        margin-top: 2rem;
      }
  
      .article-card {
        text-decoration: none;
        color: var(--text-color);
        transition: transform 0.3s ease;
        
        &:hover {
          transform: translateY(-5px);
        }
  
        .card-content {
          border: 1px solid var(--secondary-color);
          border-radius: 10px;
          padding: 1.5rem;
          height: 100%;
          
          .meta {
            display: flex;
            justify-content: space-between;
            color: var(--accent-color);
            font-size: 0.9rem;
            margin-bottom: 1rem;
          }
  
          h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
            font-weight: 600;
          }
  
          .excerpt {
            color: var(--text-color);
            margin-bottom: 1.5rem;
            line-height: 1.6;
          }
  
          .tags {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            
            .tag {
              background-color: var(--secondary-color);
              color: var(--text-color);
              padding: 0.25rem 0.75rem;
              border-radius: 15px;
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  
    &__article-detail {
      padding: 100px 0;
      display: flex;
      justify-content: center;
  
      .container {
        width: 60vw;
        
        @media (max-width: 768px) {
          width: 90vw;
        }
      }
  
      .article-header {
        margin-bottom: 3rem;
        text-align: center;
  
        .meta {
          display: flex;
          justify-content: center;
          gap: 1rem;
          color: var(--accent-color);
          margin-top: 1rem;
        }
      }
  
      .article-content {
        line-height: 1.8;
        
        h2 {
          font-size: 1.875rem;
          font-weight: 600;
          margin: 2rem 0 1rem;
        }
        
        p {
          margin-bottom: 1.5rem;
        }
        
        code {
          background-color: var(--secondary-color);
          padding: 0.2rem 0.4rem;
          border-radius: 4px;
          font-family: monospace;
        }
        
        pre {
          background-color: var(--secondary-color);
          padding: 1rem;
          border-radius: 8px;
          overflow-x: auto;
          margin: 1.5rem 0;
        }
      }
    }
  }