.reactBlue {
  // color: white;
  background-color: var(--react-blue);
}

.vueGreen {
  // color: white;
  background-color: var(--vue-green);
}

.awsOrange {
  // color: white;
  background-color: var(--aws-orange);
}

.typescriptBlue {
  color: white;
  background-color: var(--typescript-blue);
}
.nodeGreen {
  color: white;
  background-color: var(--node-green);
}
.graphqlPink {
  color: var(--light-text-color);
  background-color: var(--graphql-pink);
}
