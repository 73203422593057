@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
@import "responsive";
@import "articles";
@import "mdx-components";
// $primary-color: #2a9d8f;
// $secondary-color: #e9c46a;
// $text-color: #264653;
// $light-text-color: #f4f1de;
// $bg-color: #f4f1de;
// $accent-color: #e76f51;

// $react-blue: #61dafb;
// $vue-green: #42b883;
// $aws-orange: #ff9900;
// $typescript-blue: #3178c6;
// $node-green: #339933;
// $graphql-pink: #e10098;

// :root {
//   --primary-color: #{$primary-color};
//   --secondary-color: #{$secondary-color};
//   --text-color: #{$text-color};
//   --bg-color: #{$bg-color};
//   --accent-color: #{$accent-color};
//   --light-text-color: #{$light-text-color};
//   --react-blue: #{$react-blue};
//   --vue-green: #{$vue-green};
//   --aws-orange: #{$aws-orange};
//   --typescript-blue: #{$typescript-blue};
//   --node-green: #{$node-green};
//   --graphql-pink: #{$graphql-pink};
// }

// .theme-dark {
//   --primary-color: #2a9d8f;
//   --secondary-color: #e9c46a;
//   --text-color: #f4f1de;
//   --bg-color: #264653;
//   --accent-color: #e76f51;
// }

// body {
//   font-family: "Poppins", sans-serif;
//   background-color: var(--bg-color);
//   color: var(--text-color);
//   transition: background-color 0.3s, color 0.3s;
// }
section,
nav,
footer {
  display: flex;
  justify-content: center;
}
.creative-portfolio {
  
  &__nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // background-color: rgba(var(--bg-color), 0.8);
    // backdrop-filter: blur(10px);
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &.visible {
      transform: translateY(0);
    }
    .nav-items {
      display: flex;
      gap: 1rem;
      justify-content: center;
    }

    .nav-item {
      position: relative;
      color: var(--text-color);
      text-decoration: none;
      font-weight: 500;

      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--primary-color);
        transition: width 0.3s;
      }

      &:hover::after,
      &.active::after {
        width: 100%;
      }
    }
  }

  &__hero {
    position: relative;
    height: 100vh; // Full viewport height
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 2rem 0;

    .hero-content {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 2rem;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: 0 2rem;
    }

    .logo-container {
      position: relative;
      align-self: start; // Align to top
    }

    .hero-logo {
      position: absolute;
      top: 0;
      left: -2rem;
      width: 150px;
      height: auto;
      transform: rotate(-10deg);
      transition: transform 0.3s ease;

      &:hover {
        transform: rotate(0deg);
      }
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 2rem; // Add some padding to offset from logo
    }

    h1 {
      font-size: 4rem; // Increased size
      font-weight: 700;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }

    h2 {
      font-size: 1.75rem; // Slightly increased
      font-weight: 400;
      margin-bottom: 1.5rem;
      color: var(--accent-color);
    }

    p {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 2.5rem;
      max-width: 600px;
    }

    .cta-buttons {
      display: flex;
      gap: 1rem;
    }
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.75rem 1.5rem;
      border-radius: 30px;
      font-weight: 600;
      text-decoration: none;
      transition: all 0.3s;

      &-primary {
        background-color: var(--primary-color);
        color: white;

        &:hover {
         filter: brightness(80%);
        }
      }

      &-secondary {
        background-color: var(--secondary-color);
        color: var(--text-color);

        &:hover {
          filter: brightness(80%);
        }
      }

      &-outline {
        border: 2px solid var(--primary-color);
        color: var(--primary-color);
        background-color: transparent;

        &:hover {
          background-color: var(--primary-color);
          color: white;
        }
      }

      .icon-right {
        margin-left: 0.5rem;
      }
    }
    // Responsive adjustments
    @media (max-width: 992px) {
      height: auto; // Allow content to determine height on smaller screens
      min-height: 100vh; // Ensure it's at least full viewport height

      .hero-content {
        grid-template-columns: 1fr;
      }

      .logo-container {
        height: 120px; // Provide space for absolutely positioned logo
      }

      .hero-logo {
        width: 120px;
        left: -1rem;
      }

      .text-content {
        padding-left: 0;
      }

      h1 {
        font-size: 3rem;
      }

      h2 {
        font-size: 1.5rem;
      }
    }
  }
  &__scroll-indicator {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-bottom: 10px;
      font-size: 14px;
      opacity: 0.7;
    }

    .chevron {
      width: 28px;
      height: 8px;
      opacity: 0;
      transform: scale3d(0.5, 0.5, 0.5);
      animation: move 3s ease-out infinite;

      &:first-child {
        animation: move 3s ease-out 1s infinite;
      }

      &:nth-child(2) {
        animation: move 3s ease-out 2s infinite;
      }

      &:before,
      &:after {
        content: " ";
        position: absolute;
        top: 0;
        height: 100%;
        width: 51%;
        background: var(--text-color);
      }

      &:before {
        left: 0;
        transform: skew(0deg, 30deg);
      }

      &:after {
        right: 0;
        width: 50%;
        transform: skew(0deg, -30deg);
      }
    }
  }
  .container {
    width: 60vw;
  }
  &__about,
  &__skills,
  &__experience,
  &__projects,
  &__contact {
    padding: 100px 0;
  }

  &__tech-stack {
    
    .tech-badge {
      display: inline-block;
      padding: 8px 16px;
      // background-color: var(--secondary-color);
      //color: var(--text-color);
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__skills-chart {
    border: 1px solid #e9c46a;
    border-radius: 10px;
    padding: 1rem;
    .skill-bar {
      margin-bottom: 20px;

      .skill-name {
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
      }

      .skill-level {
        height: 10px;
        background-color: var(--primary-color);
        border-radius: 5px;
        position: relative;
        // transition: width 1s ease-in-out;
        transition: width 1s ease-out;
        .skill-percent {
          position: absolute;
          right: 0;
          top: -25px;
          font-size: 14px;
        }
      }
    }
  }

  &__timeline {
    position: relative;
    // max-width: 1200px;
    margin: 0 auto;

    &::after {
      content: "";
      position: absolute;
      width: 6px;
      background-color: var(--secondary-color);
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -3px;
    }

    .timeline-item {
      padding: 10px 0;
      position: relative;
      background-color: inherit;
      width: 50%;
      transition: transform 0.3s ease, background-color 0.3s ease;
      .timeline-content {
        padding: 0 20px;
        ul {
          text-align: left;
        }
        .timeline-header {
          display: flex;
          align-items: baseline;
          justify-content: space-around;
          h4,
          h3 {
            margin: 0;
            width: fit-content;
          }
          .title-logo {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        right: -17px;
        background-color: var(--bg-color);
        border: 4px solid var(--accent-color);
        top: 15px;
        border-radius: 50%;
        z-index: 1;
      }

      &:nth-child(even) {
        left: 50%;

        &::after {
          left: -16px;
        }
      }
      &:hover {
        transform: scale(1.02);
        background-color: rgba(var(--primary-color), 0.1);
      }
    }
  }

  &__projects {
    .projects-grid {
      display: flex;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .project-card {
        // background-color: var(--bg-color);
        border-radius: 10px;
        overflow: hidden;
        // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        // transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 45%;
        .project-header {
          padding: 20px;
          border-top: 1px solid var(--secondary-color);
        }
        &:hover {
          transform: translateY(-5px);
        }
        .project-icon {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
          background-color: white;
        }
        .project-info {
          padding: 0 20px 20px;
        }
        h3 {
          margin: 0.4rem;
          font-size: 1.2rem;
        }

        .project-tags {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 5px;
          margin-top: 10px;
        }
        .tech-tag {
          display: inline-block;
          padding: 4px 8px;
          background-color: var(--secondary-color);
          color: var(--text-color);
          border-radius: 4px;
          font-size: 12px;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__contact {
    display: flex;
    justify-content: center;
    .container {
      width: 40vw;

      @media (max-width: 768px) {
        width: 90vw; // Adjust for smaller screens
      }
    }
    .contact-link {
      display: flex;
      align-items: center;
      color: var(--text-color);
      text-decoration: none;
      margin-bottom: 10px;

      svg {
        margin-right: 10px;
      }
    }
  }

  &__footer {
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding: 20px 0;

    .social-link {
      color: var(--text-color);
      transition: color 0.3s;

      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;

  &-primary {
    background-color: var(--primary-color);
    color: white;

    &:hover {
      //background-color: var(--darken-primary-color);
      filter: brightness(80%);
    }
  }

  &-secondary {
    background-color: var(--secondary-color);
    color: var(--text-color);

    &:hover {
      //background-color: var(--darken-primary-color);
      filter: brightness(80%);
    }
  }
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-out forwards;
}

.delay-200 {
  animation-delay: 0.2s;
}

.delay-400 {
  animation-delay: 0.4s;
}

.delay-600 {
  animation-delay: 0.6s;
}

