form {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.formInput,
input,
textarea {
  padding: 10px;
  // margin-bottom: 20px;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-size: 16px;
}
textarea {
  resize: none;
}
.contactForm {
  // max-width: 500px;
  margin: 0 auto;
}

.formGroup {
  margin-bottom: 15px;
  width: 100%;
}
.errorMessage {
  color: #ff0000;
  font-size: 0.8em;
  margin-top: 5px;
  display: block;
}

.statusMessage {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;

  &.error {
    background-color: #ffebee;
    color: #c62828;
  }
}

.confirmationMessage {
  text-align: center;
  padding: 20px;
  background-color: #e8f5e9;
  border-radius: 5px;
}

.btnPrimary {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    // background-color: var(--darken-primary-color);
    filter: brightness(95%);
  }
}
